import { withRouter } from "react-router-dom";
import { ContentProps } from "components/flexComponents/Editorial/typings";
import { ContentFactory } from "components/flexComponents/Editorial/factory/ContentFactory";
import { withStores } from "stores";
import { ContentFlexModuleResult } from "src/typings/microserviceModels/content-flex-module";
import { observer } from "mobx-react";

export const Content = withRouter(
  withStores(
    "analytics",
    "compositionStore",
    "flexModuleModelStore",
    "context"
  )(
    observer((props: ContentProps) => {
      const { compositionStore, flexModuleModelStore, templateComponent } = props;

      if (!templateComponent) {
        return null;
      }
      const {
        metadata: { id },
      } = templateComponent;
      const pageHeading = compositionStore?.composition ? compositionStore.composition.pageHeading : null;
      const model = flexModuleModelStore.getModel(id) as ContentFlexModuleResult | null;
      const contentComponent = ContentFactory.get({
        ...props,
        model,
        pageHeading,
      });

      if (!contentComponent.shouldRender()) {
        return null;
      }

      return contentComponent.generate(props);
    })
  )
);

export default Content;
