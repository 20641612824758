import { ContentProps, DyanmicVendorContentFactoryProps } from "components/flexComponents/Editorial/typings";
import { ContentComponent } from "components/flexComponents/Editorial/factory/ContentComponent";
import * as React from "react";
import { DynamicVendorContentFlexModule } from "typings/microserviceModels/content-flex-module";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";
import { RenderableContentItem } from "src/components/flexComponents/Editorial/factory/impl/items/RenderableContentItem";
import RenderAbleContentItemExpando from "components/flexComponents/Editorial/factory/impl/items/RenderAbleContentItemExpando";
import { RenderableContentItemContext } from "./editorials/DefaultEditorial";

export class DynamicVendorContent extends ContentComponent {
  public readonly dynamicVendorContent: DynamicVendorContentFlexModule;

  constructor(dynamicVendorContent: DyanmicVendorContentFactoryProps) {
    super(dynamicVendorContent);
    this.dynamicVendorContent = dynamicVendorContent.model;
    this.keyHelper = new ItemKeyHelper("dynamicVendorContent");
  }

  public shouldRender = (): boolean => (this.dynamicVendorContent?.content?.body?.length || 0) > 0;

  public getId = (): string => this.dynamicVendorContent.content.id;

  public generate(props: ContentProps) {
    const classNameAttr = `${this.getEditorialItemClassName()}`;

    const renderableContextObject = {
      id: this.dynamicVendorContent.content.id,
      text: this.dynamicVendorContent.content.body,
      title: this.dynamicVendorContent.content.title,
      key: `${this.keyHelper.next()}`,
      view: this.dynamicVendorContent.view,
      context: props.context,
    };

    return (
      <div className={classNameAttr} id={this.id} data-fm={this.fmId} data-fm-title-id={this.fmTitleId}>
        {this.dynamicVendorContent.view === "destination-description" ? (
          <RenderAbleContentItemExpando
            id={this.dynamicVendorContent.content.id}
            body={this.dynamicVendorContent.content.body}
            title={this.dynamicVendorContent.content.title}
          />
        ) : (
          <RenderableContentItemContext.Provider value={renderableContextObject}>
            <RenderableContentItem />
          </RenderableContentItemContext.Provider>
        )}
      </div>
    );
  }

  public getEditorialItemClassName = (): string =>
    "Editorial dynamicVendorContent uitk-spacing uitk-spacing-padding-block-three";
}

export default DynamicVendorContent;
