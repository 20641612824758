import { ContentComponent } from "components/flexComponents/Editorial/factory/ContentComponent";
import DynamicVendorContent from "components/flexComponents/Editorial/factory/impl/DynamicVendorContent";
import {
  ContentFactoryProps,
  DyanmicVendorContentFactoryProps,
  EditorialFactoryProps,
} from "components/flexComponents/Editorial/typings";
import { EditorialContentFactory } from "components/flexComponents/Editorial/factory/EditorialContentFactory";
import { NullContentComponent } from "components/flexComponents/Editorial/factory/NullContentComponent";

export class ContentFactory {
  public static get = (props: ContentFactoryProps): ContentComponent => {
    const { name } = { ...props.model };
    if (!props.model || !name) {
      return new NullContentComponent(props);
    }

    if (name === "dynamic-vendor-content") {
      return new DynamicVendorContent(props as DyanmicVendorContentFactoryProps);
    }

    return EditorialContentFactory.get(props as EditorialFactoryProps);
  };
}
